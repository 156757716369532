body
    background-color: #f8f9fa

.btn-primary
    background-color: #0075BC
    border-color: #0075BC

.text-primary
    color: #0075BC

.mb-0 .form-group
    margin-bottom: 0

.cursor-move
    cursor: move

.cursor-pointer
    cursor: pointer

.cursor-help
    cursor: help

.overflow-visible
    overflow: visible !important

.text-body
    color: inherit

a
    color: #0075BC

a:hover
    text-decoration: none

a.dropdown-item
    margin-left:0

a.dropdown-item:active
    background: none

.text-decoration-none
    &:hover
        text-decoration: none

.ws-prewrap
    white-space: pre-wrap

.textEditor-display p
    margin-bottom:0

.ql-editor p, .ql-container
    font-size: 0.9375rem

.registration .card-header
    height: 375px
    background-size: cover
    background-position-y: center

.user-profile .card-header
    height: 240px
    background-size: cover
    background-position-y: center

.invoice-image
    height: 100px
    background-size: contain
    background-repeat: no-repeat
    background-position: 10px 10px

.avatar
    border-radius:10%

.no-results
    height: 65vh
    display: flex
    flex-direction: column
    justify-content: center

.overflow-scrolly
    max-height: 90vh
    overflow: scroll

.text-xxl
    font-size: 60px
    border: 0

.mobile-nav
    position: fixed
    bottom: 0
    width: 100%
    background-color: #333
    display: flex
    justify-content: space-around
    box-shadow: 0 -2px 10px rgba(0,0,0,0.5)
    text-align: center

.mobile-nav a
    flex-grow: 1
    text-align: center
    padding: 15px 0
    color: white
    text-decoration: none
    font-size: 16px

.mobile-nav a.active
    background-color: #555

.blank-page
    height: 40vh
    margin: 0
    display: flex
    justify-content: center
    align-items: center

.header-brand img
    height: 40px

.flex-row
    display: flex !important
    flex-direction: row
    align-items: center
    align-content: center

.justify-evenly
    justify-content: space-evenly


