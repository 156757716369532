$primary: #0075BC
$white: #ffffff
$error: #cd201f
$abyss: #001130
$deep: #003366

.btn-abyss
    background-color: #001130
    border-color: #001130

.text-abyss
    color: #001130

.btn-deep
    background-color: #003366
    border-color: #003366

.text-deep
    color: #003366